<template>
  <div>
    <div style="margin-bottom: 65px">
      <van-cell-group>
        <van-cell title="头像">
          <template #right-icon>
            <!-- <van-image
                    round
                    width="3rem"
                    height="3rem"
                    src="https://img01.yzcdn.cn/vant/cat.jpeg"
                    /> -->
            <van-uploader
              v-model="dataform.Thumb"
              multiple
              class="imgs"
              :max-count="1"
              :after-read="afterRead"
              :deletable="true"
              @delete="shanchu"
            />
          </template>
        </van-cell>
        <van-field
          v-model="dataform.name"
          label="姓名"
          placeholder="请输入姓名"
        />
        <van-field
          v-model="dataform.IdNumber"
          label="身份证号"
          placeholder="请输入身份证号"
          @blur="IdNum(dataform.IdNumber)"
        />
        <van-field name="Sex" label="性别">
          <template #input>
            <van-radio-group v-model="dataform.Sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="dataform.age"
          label="年龄"
          placeholder="请输入年龄"
        />
        <van-field
          v-model="dataform.Birthday"
          name="Birthday"
          label="出生日期"
          @click="recumenFend = true"
          readonly="readonly"
          placeholder="请选择出生日期"
        />
        <van-popup v-model="recumenFend" position="bottom" get-container="body">
          <van-datetime-picker
            v-model="currentDate"
            show-toolbar
            type="date"
            title="请选择出生日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="recumenFend = false"
            @confirm="onrecumenFend"
          >
          </van-datetime-picker>
        </van-popup>
        <van-field
          v-model="dataform.DiseaseCircs"
          label="疾病类型"
          placeholder="请选择疾病类型"
          @click="jibingPicker = true"
          readonly="readonly"
          clickable
        />
        <van-popup v-model="jibingPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="jibingList"
            value-key="label"
            @confirm="jibingQr"
            @cancel="jibingPicker = false"
          />
        </van-popup>
        <van-field
          v-model="dataform.OrganName"
          is-link
          readonly
          label="社区"
          placeholder="请选择社区"
          @click="showSq = true"
        />
        <van-popup v-model="showSq" round position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="请选择社区"
            :options="orgonList"
            @close="showSq = false"
            :field-names="{
              text: 'OName',
              value: 'OCode',
              children: 'Children',
            }"
            @finish="clickSq"
          />
        </van-popup>
        <van-field v-model="dataform.jhName" label="监护人" placeholder="请输入监护人" />
        <van-field
          v-model="dataform.phone"
          label="监护人电话"
          placeholder="请输入监护人电话"
        />
      </van-cell-group>
    </div>
    <div
      style="
        width: 90%;
        margin: 10px auto;
        bottom: 0;
        position: fixed;
        padding: 10px;
      "
    >
      <van-button style="width: 100%" round type="info" @click="update()"
        >提交</van-button
      >
    </div>
  </div>
</template>
<script>
import { WeGetOrganTree } from "@/api/Organ";
import config from "@/config";
export default {
  data() {
    return {
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2050, 10, 1),
      dataform: {
        IdNumber: "",
        Sex: "",
        age: "",
        DiseaseCircs: "",
        Thumb: "https://img01.yzcdn.cn/vant/cat.jpeg",
        jhName:"",
        phone:"",
      },
      jibingList: [
        { value: "01", label: "高血压" },
        { value: "02", label: "糖尿病" },
        { value: "03", label: "血糖异常" },
        { value: "04", label: "冠心病" },
        { value: "05", label: "慢性阻塞性肺疾病" },
        { value: "06", label: "恶性肿瘤" },
        { value: "07", label: "重型精神病" },
        { value: "08", label: "肝炎" },
        { value: "09", label: "其他" },
      ],
      jibingPicker: false, //疾病状况
      recumenFend: false, //出生日期
      orgonList: [],
      showSq: false,
      currentDate: new Date(2021, 0, 17),
      cascaderValue: "",
    };
  },
  mounted() {
    this.getOrgonTree();
  },
  methods: {
    update: function () {
      this.$router.push("/pension/xinXiBianJi/");
    },
        // 图片上传
    afterRead(file) {
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file);
      // 添加请求头
      axios.post(config.apiUploadUrl, formData, c).then((res) => {
        if (res.data.code === 0) {
          var arr = this.uploadImgs;
          arr.push(res.data.data);
          this.uploadImgs = arr;
        }
        this.recumenA.Photo = res.data.picurl;
      });
    },
    // 图片删除
    shanchu: function () {
      console.log(123);
      this.dataform.Thumb = "";
    },
    // 所属社区
    getOrgonTree() {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data;
      });
    },
    onrecumenFend(val) {
      //出生日期 确认
      this.dataform.Birthday = this.dateformat(val);
      this.recumenFend = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    clickSq({ selectedOptions }) {
      console.log(1);
      console.log(selectedOptions);
      this.showSq = false;
      this.dataform = {
        page: 1,
        limit: 10,
        isShowChild: false,
        organCode: "",
        title: "",
        Birthday: "",
      };
      this.fieldValue = selectedOptions.map((option) => option.OName).join("/");
      this.OCode = selectedOptions.map((option) => option.OCode).join("/");
      this.dataform.OrganName = this.fieldValue;
      this.dataform.organCode = selectedOptions[1].OCode;
      console.log(this.fieldValue);
      this.getXqTree(this.dataform.organCode);
    },
    //疾病类型
    jibingQr(val) {
      console.log(val);

      this.dataform.DiseaseCircs = val.label;
      this.dataform.DiseaseName = val.value;
      this.jibingPicker = false;
    },
    // 获取身份证
    IdNum(value) {
      console.log(value);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (value.length > 0) {
        if (reg.test(value)) {
          this.go(value.length);
        } else {
          Toast.fail("身份证号码不正确");
        }
      }
    },
    go(val) {
      var iden =
        this.dataform.IdNumber == ""
          ? this.dataform.IdNumber
          : this.dataform.IdNumber;
      // var iden = this.dataForms.IdNumber;
      console.log(iden);
      var id = iden.substring(0, 6);
      this.dataform.NativeCantonCode = id;
      console.log(this.dataform.NativeCantonCode);
      var sex = null;
      var birth = null;
      var myDate = new Date();
      var month = myDate.getMonth() + 1;
      var day = myDate.getDate();
      var age = 0;
      if (val === 18) {
        age = myDate.getFullYear() - iden.substring(6, 10) - 1;
        sex = iden.substring(16, 17);
        birth =
          iden.substring(6, 10) +
          "-" +
          iden.substring(10, 12) +
          "-" +
          iden.substring(12, 14);
        if (
          iden.substring(10, 12) < month ||
          (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)
        )
          age++;
      }
      if (val === 15) {
        age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
        sex = iden.substring(13, 14);
        birth =
          "19" +
          iden.substring(6, 8) +
          "-" +
          iden.substring(8, 10) +
          "-" +
          iden.substring(10, 12);
        if (
          iden.substring(8, 10) < month ||
          (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)
        )
          age++;
      }
      if (sex % 2 == 0) {
        sex = 2;
        this.dataform.Sex = sex + "";
        this.age = age;
        this.dataform.age = age;
        this.dataform.Birthday = birth;
        this.currentDate = new Date(this.dataform.Birthday);
      } else {
        sex = 1;
        // this.hzlist.Sex = sex;
        this.dataform.Sex = sex + "";
        this.age = age;
        this.dataform.age = age;
        this.dataform.Birthday = birth;
        this.currentDate = new Date(this.dataform.Birthday);
      }
    },
  },
};
</script>
<style scoped>
.title-class {
  min-width: 30% !important;
}
.value-class {
  min-width: 60% !important;
}
.block {
  width: 100%;
  background: #fff;
  margin: 15px 4%;
}
.block .title {
  text-align: center;
  padding: 8px 0px;
}
.van-cell {
  font-size: 16px;
  font-weight: 540;
  font-family: -webkit-pictograph;
  height: 56px;
}
</style>